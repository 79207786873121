@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

$ada_primary_colors: (
  50: #fae1ed,
  100: #f3b3d1,
  200: #eb81b2,
  300: #e34f93,
  400: #dd297c,
  500: #d70365,
  600: #d3035d,
  700: #cd0253,
  800: #c70249,
  900: #be0137,
  A100: #ffe6ec,
  A200: #ffb3c4,
  A400: #ff809c,
  A700: #ff6788,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$ada_secondary_color: (
  50: #e3f5fc,
  100: #b8e5f7,
  200: #89d4f2,
  300: #59c3ec,
  400: #36b6e8,
  500: #12a9e4,
  600: #10a2e1,
  700: #0d98dd,
  800: #0a8fd9,
  900: #057ed1,
  A100: #fafdff,
  A200: #c7e6ff,
  A400: #94cfff,
  A700: #7bc3ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);
$theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($ada_primary_colors),
    accent: mat.define-palette($ada_secondary_color),
  ),
  typography: mat.define-typography-config($font-family: 'Open sans' ),
));

@include mat.all-component-themes($theme);
@include mat.core()
